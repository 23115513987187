import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'
import {
  Accordion,
  AccordionItem,
  Checkbox,
  ProsCons,
  TooltipWithButton,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import { CrossBoldIcon, TickBoldIcon } from '@which/seatbelt/src/components/Icons'
import { SolidPadlockIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous/SolidPadlock'
import { dynamicDatalayerPush, dynamicGa4DataLayerPush } from '@which/shared'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import classnames from 'classnames'

import type { Comparison } from '../../../../../shared/types/ReviewsCompareTray'
import { usePageProps } from '../../../../../shared/usePageProps'
import { StarRating } from '../../../product-page/components/StarRating'
import { StarRatingBadge } from '../../../product-page/components/StarRatingBadge'
import type { ComparisonTableState } from '../../ComparisonTableContext'
import { ComparisonTableContext } from '../../ComparisonTableContext'
import { TABLE_ROWS_TO_BE_REFINED } from '../../reducers/comparisonTableActionTypes'
import { totalProductColumns } from './ComparisonTable'
import styles from './ComparisonTable.module.scss'
import { FillerCells } from './FillerCells/FillerCells'
import type { OnTableRowCheckboxArgs } from './types'

export const ComparisonTableData: FunctionComponent<ComparisonTableDataProps> = ({
  taxonomySlug,
  renderWithAccordion = false,
  compareTrayItems,
}) => {
  const {
    state: { productDetails, tableData, refineStatus, rowsToBeRefined },
    dispatch,
  } = useContext(ComparisonTableContext)
  const renderProsAndCons = useFeatureIsOn('whc-compare-proscons')
  const useStarRatingBadge = useFeatureIsOn('whc-compare-star-rating')
  const useTickCrossIcons = useFeatureIsOn('whc-compare-tick-cross')
  const { userAccessState } = usePageProps()
  const isPaidMember = ['AUTHENTICATED_FULL_ACCESS', 'FULL_ACCESS'].includes(
    userAccessState?.transformTypeDecision ?? ''
  )

  if (!tableData?.length) {
    return null
  }

  const testResults: ComparisonTableState['tableData'] = []
  const techSpecs: ComparisonTableState['tableData'] = []

  tableData.forEach((item) => {
    item.dataType === 'test-result' ? testResults.push(item) : techSpecs.push(item)
  })

  const handleAccordionToggle = (isOpen: boolean, sectionName: string) => {
    dynamicGa4DataLayerPush({
      event: 'click_accordion',
      utagid: 'WHC424DP01',
      item_text: sectionName,
      action_group: isOpen ? 'expand' : 'collapse',
      item_group: 'product compare',
    })
  }

  const onTableRowCheckbox = ({ value, sectionLabel, rowLabel }: OnTableRowCheckboxArgs) => {
    dispatch({
      type: TABLE_ROWS_TO_BE_REFINED,
      row: value,
      sectionLabel,
    })

    dynamicDatalayerPush({
      eventCategory: 'Refine Results',
      eventAction: `${taxonomySlug} - ${sectionLabel.toLowerCase()} - ${rowLabel.toLowerCase()}`,
      eventLabel: `${!rowsToBeRefined[sectionLabel]?.includes(value)}`,
    })
  }

  const renderTableData = (data: ComparisonTableState['tableData'], sectionHeading = '') => {
    const innerData = data?.map(({ label: sectionLabel, rows, dataType }) => (
      <React.Fragment key={`${sectionLabel}-${dataType}`}>
        {rows.length > 0 && (
          <tr>
            <td className={styles.sectionHeader} colSpan={5}>
              <Typography textStyle="sb-text-body-default-strong" tag="span">
                {sectionLabel}
              </Typography>
            </td>
          </tr>
        )}
        {rows.map(({ rowLabel, rowValues, helpText }, rowIndex) => {
          return (
            <tr
              className={styles.productFeatureRow}
              key={`${rowLabel}-${rowIndex}`}
              data-testid={dataType === 'test-result' ? 'test-results-row' : 'tech-spec-row'}
            >
              <td className={classnames(styles.tableDataRowHead, styles.stickyFirstColumn)}>
                {refineStatus === 'refine' && (
                  <Checkbox
                    id={`${sectionLabel}-${rowLabel}-${rowIndex}`}
                    name={`${sectionLabel}-${rowLabel}-${rowIndex}`}
                    label={rowLabel}
                    value={rowLabel}
                    onChangeCallback={({ value }) =>
                      value && onTableRowCheckbox({ value, sectionLabel, rowLabel })
                    }
                  />
                )}
                {refineStatus !== 'refine' && (
                  <>
                    <Typography
                      textStyle="sb-text-interface-body-small-regular"
                      className={styles.tableDataRowLabel}
                      tag="span"
                    >
                      {rowLabel}
                    </Typography>
                    {helpText && (
                      <TooltipWithButton
                        ariaLabel={rowLabel}
                        title={rowLabel}
                        contents={helpText}
                        openHandler={() => {
                          dynamicGa4DataLayerPush({
                            event: 'click_tooltip',
                            utagid: 'WHC517DP01',
                            item_text: rowLabel,
                            item_parent_text: sectionLabel,
                            item_group: 'compare column',
                          })
                        }}
                      />
                    )}
                  </>
                )}
              </td>
              {rowValues.map(({ value }, valIndex) => (
                <td className={styles.tableDataRowValue} key={`${value}-${valIndex}`}>
                  {getValueElement({ value, dataType, useStarRatingBadge, useTickCrossIcons })}
                </td>
              ))}
              <FillerCells
                className={styles.tableDataRowValue}
                count={totalProductColumns - rowValues.length}
              />
            </tr>
          )
        })}
      </React.Fragment>
    ))

    if (renderWithAccordion) {
      return (
        <AccordionTableWrapper id={sectionHeading} compareTrayItems={compareTrayItems || []}>
          {innerData}
        </AccordionTableWrapper>
      )
    }

    return innerData
  }

  const renderProsAndConsData = (data: ComparisonTableState['productDetails']) => {
    const innerData = (
      <>
        <tr className={styles.productFeatureRow}>
          <td className={styles.tableDataRowHead}>Pros</td>
          {data.map((product) => (
            <td className={styles.tableDataRowValue} key={`pros-${product.businessKey}`}>
              <ProsCons
                className={styles.prosConsList}
                type="pros"
                list={isPaidMember ? product.pros : []}
                displayHeading={false}
              />
            </td>
          ))}
          <FillerCells
            className={styles.tableDataRowValue}
            count={totalProductColumns - productDetails.length}
          />
        </tr>
        <tr className={styles.productFeatureRow}>
          <td className={styles.tableDataRowHead}>Cons</td>
          {data.map((product) => (
            <td className={styles.tableDataRowValue} key={`cons-${product.businessKey}`}>
              <ProsCons
                className={styles.prosConsList}
                type="cons"
                list={isPaidMember ? product.cons : []}
                displayHeading={false}
              />
            </td>
          ))}
          <FillerCells
            className={styles.tableDataRowValue}
            count={totalProductColumns - productDetails.length}
          />
        </tr>
      </>
    )
    return (
      <AccordionTableWrapper
        id={'pros-cons'}
        className={styles.prosAndCons}
        compareTrayItems={compareTrayItems || []}
      >
        {innerData}
      </AccordionTableWrapper>
    )
  }

  return renderWithAccordion ? (
    <div
      className={classnames(styles.productData, {
        [styles.compareWithAccordion]: renderWithAccordion,
      })}
    >
      <Accordion>
        {renderProsAndCons && productDetails && (
          <AccordionItem
            label="Pros & cons"
            content={renderProsAndConsData(productDetails)}
            renderOpen
            callback={(isOpen: boolean) => {
              handleAccordionToggle(isOpen, 'Pros & cons')
            }}
          />
        )}
        {testResults.length > 0 && (
          <AccordionItem
            label="Test results"
            content={renderTableData(testResults, 'test-results')}
            renderOpen
            callback={(isOpen: boolean) => {
              handleAccordionToggle(isOpen, 'Test results')
            }}
          />
        )}
        {techSpecs.length > 0 && (
          <AccordionItem
            label="Specifications"
            content={renderTableData(techSpecs, 'tech-specs')}
            renderOpen
            callback={(isOpen: boolean) => {
              handleAccordionToggle(isOpen, 'Specifications')
            }}
          />
        )}
      </Accordion>
    </div>
  ) : (
    <>{renderTableData(tableData)}</>
  )
}

type ComparisonTableDataProps = {
  taxonomySlug: string
  renderWithAccordion?: boolean
  compareTrayItems?: Comparison[]
}

const AccordionTableWrapper: React.FC<{
  id: string
  className?: string
  compareTrayItems: Comparison[]
  children: React.ReactNode
}> = ({ id, className, compareTrayItems, children }) => {
  return (
    <table key={`${id}-table`} className={classnames(styles.comparisonTable, className)}>
      {compareTrayItems && (
        <thead className={styles.visuallyHidden}>
          <tr>
            <FillerCells count={1} cellType={'th'} />
            {compareTrayItems?.map((product) => (
              <th
                data-testid="table-head-cell"
                key={`wrapper-${product.model}`}
              >{`${product.manufacturer} ${product.model}`}</th>
            ))}
            <FillerCells count={totalProductColumns - compareTrayItems.length} cellType={'th'} />
          </tr>
        </thead>
      )}
      <tbody>{children}</tbody>
    </table>
  )
}

const getValueElement = ({
  value,
  dataType,
  useStarRatingBadge,
  useTickCrossIcons,
}: {
  value: string
  dataType: string
  useStarRatingBadge: boolean
  useTickCrossIcons: boolean
}) => {
  if (value === '') {
    return (
      <div className={styles.iconContainer}>
        <SolidPadlockIcon data-testid="padlock-icon" />
      </div>
    )
  }

  const formattedValue = isNaN(Number(value)) ? value.toLowerCase().trim() : value

  const renderIcon = (IconComponent: React.ElementType, ariaLabel: string, icon: string) => (
    <>
      <Typography
        textStyle="sb-text-interface-body-small-regular"
        tag="span"
        className={styles.visuallyHidden}
      >
        {value}
      </Typography>
      <IconComponent
        className={styles[`${icon}Icon`]}
        aria-label={ariaLabel}
        data-testid={`${icon}-icon`}
      />
    </>
  )

  if (formattedValue === 'yes' || formattedValue === 'no') {
    const icon = formattedValue === 'yes' ? TickBoldIcon : CrossBoldIcon
    return useTickCrossIcons
      ? renderIcon(icon, formattedValue, formattedValue === 'yes' ? 'tick' : 'cross')
      : value
  }

  return (
    <Typography
      textStyle="sb-text-interface-body-small-regular"
      tag="span"
      className={styles.tableValue}
    >
      {dataType === 'test-result' ? getStarRating(value, useStarRatingBadge) : value}
    </Typography>
  )
}

const getStarRating = (value: string, useStarRatingBadge: boolean) => {
  return useStarRatingBadge ? <StarRatingBadge value={value} /> : <StarRating value={value} />
}
