import type { FunctionComponent } from 'react'
import { useContext, useRef } from 'react'
import { useIntersection } from 'react-use'
import { GridItem } from '@which/seatbelt'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import classnames from 'classnames'

import { Loader } from '../../../../../shared/components/Loader'
import { ReviewsCompareTray } from '../../../../../shared/components/ReviewsCompareTray/ReviewsCompareTray'
import { compareProductsStorageName } from '../../../../../shared/constants/compare-products-storage-name'
import { useCompareTray } from '../../../../../shared/hooks/useCompareTray'
import { useMatchMedia } from '../../../../../shared/hooks/useMatchMedia'
import { usePaths } from '../../../../../shared/hooks/usePaths'
import { ComparisonTableContext } from '../../ComparisonTableContext'
import { REMOVE_TECH_SPECS_COLUMN } from '../../reducers/comparisonTableActionTypes'
import { EmptyCompareLandingPage } from '../EmptyCompareLandingPage/EmptyCompareLandingPage'
import styles from './ComparisonTable.module.scss'
import { ComparisonTableData } from './ComparisonTableData'
import { ProductDetails } from './ProductDetails'
import { ComparisonTableV2 } from './v2/ComparisonTableV2'

export const ComparisonTable: FunctionComponent = () => {
  const { dispatch } = useContext(ComparisonTableContext)
  const { categorySlug } = usePaths()
  const taxonomySlug = categorySlug || ''
  const { compareTrayItems, removeProduct_CompareTrayHandler } = useCompareTray(
    compareProductsStorageName,
    taxonomySlug
  )
  const intersectionRef = useRef(null)
  const tableHeadRef = useRef<HTMLTableSectionElement>(null)
  const intersectionTableTop = useIntersection(intersectionRef, {
    threshold: 1,
  })
  const isSticky = intersectionTableTop && intersectionTableTop.boundingClientRect.top < 0
  const renderWithAccordion = useFeatureIsOn('whc-compare-accordion')

  const removeProductColumn = (businessKey: string, index?: number) => {
    removeProduct_CompareTrayHandler(businessKey, 'Remove From Compare - Compare Page')
    dispatch({
      type: REMOVE_TECH_SPECS_COLUMN,
      index,
    })
  }

  const isDesktopOrAbove: boolean = useMatchMedia('(min-width: 1024px)')

  if (!compareTrayItems) {
    return <Loader />
  }

  return (
    <GridItem span={{ small: 'full-bleed', medium: 'full-bleed' }}>
      {compareTrayItems.length ? (
        <>
          {isDesktopOrAbove ? (
            <>
              <div
                id="io-element"
                data-testid="reviews-compare-intersection"
                ref={intersectionRef}
                style={
                  tableHeadRef.current && isSticky
                    ? { height: `${tableHeadRef.current?.clientHeight}px` }
                    : { height: 0 }
                }
              />
              <div
                className={classnames(styles.tableWrapper, isSticky && styles.isSticky)}
                data-testid="comparison-table-wrapper"
              >
                <table className={classnames(styles.comparisonTable)}>
                  <thead
                    ref={tableHeadRef}
                    className={styles.comparisonTableHead}
                    data-testid="comparison-table-head"
                  >
                    <ReviewsCompareTray
                      className={styles.compareTrayHeadRow}
                      taxonomySlug={taxonomySlug}
                      products={compareTrayItems}
                      removeHandler={removeProductColumn}
                      variant="header"
                    />
                  </thead>
                  <tbody data-testid="comparison-table-body">
                    <ProductDetails taxonomySlug={taxonomySlug} />
                    {!renderWithAccordion && <ComparisonTableData taxonomySlug={taxonomySlug} />}
                  </tbody>
                </table>
                {renderWithAccordion && (
                  <ComparisonTableData
                    taxonomySlug={taxonomySlug}
                    renderWithAccordion={renderWithAccordion}
                    compareTrayItems={compareTrayItems}
                  />
                )}
              </div>
            </>
          ) : (
            <ComparisonTableV2
              taxonomySlug={taxonomySlug}
              renderWithAccordion={renderWithAccordion}
            />
          )}
        </>
      ) : (
        <EmptyCompareLandingPage
          data-testid="emptyCompareLandingPage"
          taxonomySlug={taxonomySlug}
          numberOfProducts={compareTrayItems.length}
        />
      )}
    </GridItem>
  )
}

//////IMPLEMENTATION//////

export const totalProductColumns = 4
