import type { FunctionComponent } from 'react'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Accordion,
  AccordionItem,
  Checkbox,
  ProsCons,
  TooltipWithButton,
  TypographyV2,
} from '@which/seatbelt'
import { CrossBoldIcon, TickBoldIcon } from '@which/seatbelt/src/components/Icons'
import { SolidPadlockIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous/SolidPadlock'
import { dynamicDatalayerPush, dynamicGa4DataLayerPush } from '@which/shared'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import classnames from 'classnames'

import { SVGUtil } from '../../../../../../shared/components/SVGUtil'
import { SVGName } from '../../../../../../shared/components/SVGUtil/svgs'
import { useMatchMedia } from '../../../../../../shared/hooks/useMatchMedia'
import { usePageProps } from '../../../../../../shared/usePageProps'
import { StarRating } from '../../../../product-page/components/StarRating'
import { StarRatingBadge } from '../../../../product-page/components/StarRatingBadge'
import type { ComparisonTableState } from '../../../ComparisonTableContext'
import { ComparisonTableContext } from '../../../ComparisonTableContext'
import { TABLE_ROWS_TO_BE_REFINED } from '../../../reducers/comparisonTableActionTypes'
import tableStyles from '../ComparisonTable.module.scss'
import { Product } from '../ProductDetails/Product'
import { RefineComparisonTableButtons } from '../RefineComparisonTableButtons'
import type { OnTableRowCheckboxArgs } from '../types'
import styles from './ComparisonTableV2.module.scss'

export const ComparisonTableV2: FunctionComponent<ComparisonTablePageProps> = ({
  taxonomySlug,
  renderWithAccordion,
}) => {
  const isDesktopOrAbove: boolean = useMatchMedia('(min-width: 1024px)')
  const [maxProductsToCompare, setMaxProductsToCompare] = useState(2)
  const totalProductColumns = 4

  const {
    state: { productDetails, tableData, refineStatus, rowsToBeRefined },
    dispatch,
  } = useContext(ComparisonTableContext)

  const filterProductDetails = () => {
    return Array.from(productDetails?.keys())?.splice(0, maxProductsToCompare)
  }

  const [productIndexesForComparison, setProductIndexesForComparison] =
    useState<number[]>(filterProductDetails())

  useEffect(() => {
    isDesktopOrAbove ? setMaxProductsToCompare(4) : setMaxProductsToCompare(2)
  }, [isDesktopOrAbove])

  useEffect(() => {
    setProductIndexesForComparison(filterProductDetails())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxProductsToCompare])

  const showRefine: boolean = isDesktopOrAbove && refineStatus === 'refine'

  const { userAccessState } = usePageProps()
  const isPaidMember = ['AUTHENTICATED_FULL_ACCESS', 'FULL_ACCESS'].includes(
    userAccessState?.transformTypeDecision ?? ''
  )
  const renderProsAndCons = useFeatureIsOn('whc-compare-proscons')
  const useStarRatingBadge = useFeatureIsOn('whc-compare-star-rating')
  const useTickCrossIcons = useFeatureIsOn('whc-compare-tick-cross')

  const hasOneProductBadge = useMemo(
    () => productDetails?.some((productDetail) => productDetail.badges.length),
    [productDetails]
  )

  const onTableRowCheckbox = ({ value, sectionLabel, rowLabel }: OnTableRowCheckboxArgs) => {
    dispatch({
      type: TABLE_ROWS_TO_BE_REFINED,
      row: value,
      sectionLabel,
    })

    dynamicDatalayerPush({
      eventCategory: 'Refine Results',
      eventAction: `${taxonomySlug} - ${sectionLabel.toLowerCase()} - ${rowLabel.toLowerCase()}`,
      eventLabel: `${!rowsToBeRefined[sectionLabel]?.includes(value)}`,
    })
  }

  const tooltipOpenHandler = useCallback(() => {
    dynamicGa4DataLayerPush({
      eventCategory: 'Product Compare',
      eventAction: 'Tooltip',
      eventLabel: `${taxonomySlug} - Product Card - Test Score`,
    })
  }, [taxonomySlug])

  if (!productDetails?.length || !tableData?.length) {
    return null
  }

  const changeProductHandler = (
    event: React.ChangeEvent<HTMLSelectElement>,
    indexToReplace: number
  ) => {
    const currentTarget = event?.currentTarget

    if (currentTarget) {
      const newIndex = parseInt(currentTarget?.value)

      dynamicGa4DataLayerPush({
        utagid: 'RS2947DP01',
        event: 'click_compare',
        action_group: 'switch product',
        item_group: 'product compare',
        item_text: currentTarget[currentTarget.selectedIndex].textContent ?? undefined,
      })

      setProductIndexesForComparison((currentIndexes) =>
        currentIndexes.map((currentIndex, i) => (i === indexToReplace ? newIndex : currentIndex))
      )
    }
  }

  const selectClickHandler = (event: React.MouseEvent<HTMLSelectElement>) => {
    const currentTarget = event?.currentTarget

    if (currentTarget) {
      dynamicGa4DataLayerPush({
        utagid: 'RS2947DP01',
        event: 'click_compare',
        action_group: 'switch product',
        item_group: 'product compare',
        item_text: 'change product',
      })
    }
  }

  const productDetailsForComparison = productIndexesForComparison.map((i) => productDetails[i])

  const tableDataForComparison = tableData.map((tableData) => {
    return {
      ...tableData,
      rows: tableData.rows.map((row) => {
        return {
          ...row,
          rowValues: productIndexesForComparison.map((i) => row.rowValues[i]),
        }
      }),
    }
  })

  const testResults: ComparisonTableState['tableData'] = []
  const techSpecs: ComparisonTableState['tableData'] = []

  tableDataForComparison.forEach((item) => {
    item.dataType === 'test-result' ? testResults.push(item) : techSpecs.push(item)
  })

  const handleAccordionToggle = (isOpen: boolean, sectionName: string) => {
    dynamicGa4DataLayerPush({
      event: 'click_accordion',
      utagid: 'WHC424DP01',
      item_text: sectionName,
      action_group: isOpen ? 'expand' : 'collapse',
      item_group: 'product compare',
    })
  }

  return (
    <div
      className={classnames(styles.tableWrapper, {
        [styles.compareWithAccordion]: renderWithAccordion,
        [styles.tableWrapperDesktop]: isDesktopOrAbove,
      })}
      data-testid="comparison-table-v2"
    >
      <section className={classnames(styles.header, styles.section)}>
        <ul>
          {isDesktopOrAbove ? (
            <li>
              <TypographyV2
                className={tableStyles.refineCellText}
                textStyle="sb-text-body-default-strong"
              >
                Compare
              </TypographyV2>
              <RefineComparisonTableButtons />
            </li>
          ) : (
            <li className={styles.filler} />
          )}
          {productDetailsForComparison.map((productDetail, productIndex) => (
            <li key={`header-${productDetail?.businessKey}`}>
              <TypographyV2 textStyle="sb-text-body-default-strong" data-testid="manufacturer-name">
                {productDetail?.manufacturer?.name}
              </TypographyV2>
              <TypographyV2 textStyle="sb-text-body-default-regular" data-testid="model">
                {productDetail?.model}
              </TypographyV2>
              {productDetails.length > maxProductsToCompare && (
                <div className={styles.selectWrapper}>
                  <select
                    name={`select-${productDetail?.businessKey}`}
                    aria-label="Change product"
                    data-testid={`select-${productDetail?.businessKey}`}
                    onChange={(event) => changeProductHandler(event, productIndex)}
                    onClick={(event) => selectClickHandler(event)}
                  >
                    <option>Change product</option>
                    {productDetails.map(
                      (innerProductDetail, i) =>
                        !productIndexesForComparison.includes(i) && (
                          <option
                            value={i}
                            key={`select-${productIndex}-option-${innerProductDetail?.businessKey}`}
                          >
                            {innerProductDetail?.manufacturer?.name} {innerProductDetail?.model}
                          </option>
                        )
                    )}
                  </select>
                  <SVGUtil
                    name={SVGName.Change}
                    width={15}
                    height={15}
                    viewBox="0 0 15 15"
                    title="Change product icon"
                    className={styles.icon}
                  />
                </div>
              )}
            </li>
          ))}
        </ul>
      </section>

      <section className={classnames(styles.productDetails, styles.section)}>
        <div className={styles.filler} />
        {productDetailsForComparison.map((productDetail) => (
          <Product
            key={productDetail.businessKey}
            hasOneProductBadge={hasOneProductBadge}
            taxonomySlug={taxonomySlug}
            tooltipOpenHandler={tooltipOpenHandler}
            tag="div"
            {...productDetail}
          />
        ))}
        {isDesktopOrAbove && productDetailsForComparison.length < totalProductColumns && (
          <div className={styles.filler} />
        )}
      </section>
      {renderWithAccordion ? (
        <div className={styles.accordionWrapper} data-testid="accordion-wrapper">
          <Accordion>
            {renderProsAndCons && productDetails && (
              <AccordionItem
                label="Pros & cons"
                content={renderProsAndConsData(productDetailsForComparison, isPaidMember)}
                callback={(isOpen: boolean) => {
                  handleAccordionToggle(isOpen, 'Pros & cons')
                }}
              />
            )}
            <AccordionItem
              label="Test results"
              content={renderTableData({
                tableData: testResults,
                useStarRatingBadge,
                useTickCrossIcons,
                showRefine,
                onTableRowCheckbox,
              })}
              callback={(isOpen: boolean) => {
                handleAccordionToggle(isOpen, 'Test results')
              }}
            />
            <AccordionItem
              label="Specifications"
              content={renderTableData({
                tableData: techSpecs,
                useStarRatingBadge,
                useTickCrossIcons,
                showRefine,
                onTableRowCheckbox,
              })}
              callback={(isOpen: boolean) => {
                handleAccordionToggle(isOpen, 'Specifications')
              }}
            />
          </Accordion>
        </div>
      ) : (
        renderTableData({
          tableData: tableDataForComparison,
          useStarRatingBadge,
          useTickCrossIcons,
          showRefine,
          onTableRowCheckbox,
        })
      )}
    </div>
  )
}

const getValueElement = ({
  value,
  dataType,
  useStarRatingBadge,
  useTickCrossIcons,
}: {
  value: string
  dataType: string
  useStarRatingBadge: boolean
  useTickCrossIcons: boolean
}) => {
  if (value === '') {
    return (
      <SolidPadlockIcon
        className={styles.padlock}
        data-testid="padlock-icon"
        width={15}
        height={15}
        viewBox="0 0 15 15"
      />
    )
  }

  const formattedValue = isNaN(Number(value)) ? value.toLowerCase().trim() : value

  const renderIcon = (IconComponent: React.ElementType, ariaLabel: string, icon: string) => (
    <>
      <TypographyV2
        textStyle="sb-text-interface-body-small-regular"
        tag="span"
        className={styles.visuallyHidden}
      >
        {value}
      </TypographyV2>
      <IconComponent
        className={styles[`${icon}Icon`]}
        aria-label={ariaLabel}
        data-testid={`${icon}-icon`}
      />
    </>
  )

  if (formattedValue === 'yes' || formattedValue === 'no') {
    const icon = formattedValue === 'yes' ? TickBoldIcon : CrossBoldIcon
    return useTickCrossIcons
      ? renderIcon(icon, formattedValue, formattedValue === 'yes' ? 'tick' : 'cross')
      : value
  }

  return (
    <TypographyV2
      textStyle="sb-text-interface-body-small-regular"
      tag="span"
      className={styles.tableValue}
    >
      {dataType === 'test-result' ? getStarRating(value, useStarRatingBadge) : value}
    </TypographyV2>
  )
}

///////// IMPLEMENTATION /////////

const getStarRating = (value: string, useStarRatingBadge: boolean) => {
  return useStarRatingBadge ? <StarRatingBadge value={value} /> : <StarRating value={value} />
}

const renderTableData = ({
  tableData,
  useStarRatingBadge,
  useTickCrossIcons,
  showRefine,
  onTableRowCheckbox,
}: RenderTableDataArgs) => {
  return tableData?.map(({ label: sectionLabel, rows, dataType }, sectionIndex) => (
    <div key={`${sectionLabel}-${sectionIndex}`} className={styles.compareSection}>
      {sectionLabel && (
        <TypographyV2 textStyle="sb-text-body-default-strong">{sectionLabel}</TypographyV2>
      )}
      {rows.map(({ rowLabel, rowValues, helpText }, rowIndex) => {
        return (
          <div
            className={styles.productFeatureRow}
            key={`${rowLabel}-${rowIndex}`}
            data-testid={dataType === 'test-result' ? 'test-results-row' : 'tech-spec-row'}
          >
            <div className={styles.rowLabel}>
              {showRefine ? (
                <Checkbox
                  id={`${sectionLabel}-${rowLabel}-${rowIndex}`}
                  name={`${sectionLabel}-${rowLabel}-${rowIndex}`}
                  label={rowLabel}
                  value={rowLabel}
                  onChangeCallback={({ value }) =>
                    value && onTableRowCheckbox({ value, sectionLabel, rowLabel })
                  }
                />
              ) : (
                <>
                  <TypographyV2
                    textStyle="sb-text-interface-body-small-regular"
                    className={styles.rowLabelText}
                    tag="span"
                  >
                    {rowLabel}
                  </TypographyV2>
                  {helpText && (
                    <TooltipWithButton
                      ariaLabel={rowLabel}
                      title={rowLabel}
                      contents={helpText}
                      openHandler={() =>
                        dynamicGa4DataLayerPush({
                          event: 'click_tooltip',
                          utagid: 'WHC517DP01',
                          item_text: rowLabel,
                          item_parent_text: sectionLabel,
                          item_group: 'compare column',
                        })
                      }
                    />
                  )}
                </>
              )}
            </div>
            {rowValues.map(({ value }, valIndex) => (
              <div className={styles.tableDataRowValue} key={`${value}-${valIndex}`}>
                {getValueElement({ value, dataType, useStarRatingBadge, useTickCrossIcons })}
              </div>
            ))}
          </div>
        )
      })}
    </div>
  ))
}

const renderProsAndConsData = (
  data: ComparisonTableState['productDetails'],
  isPaidMember: boolean
) => (
  <div
    key={`pros-and-cons-section`}
    className={classnames(styles.compareSection, styles.prosAndCons)}
  >
    <div className={styles.productFeatureRow} data-testid={'pros-row'}>
      <div className={styles.rowLabel}>
        <TypographyV2 textStyle="sb-text-interface-body-small-regular" tag="span">
          Pros
        </TypographyV2>
      </div>
      {data.map((product) => (
        <div className={styles.tableDataRowValue} key={`pros-${product.businessKey}`}>
          <ProsCons
            className={styles.prosConsList}
            type="pros"
            list={isPaidMember ? product.pros : []}
            displayHeading={false}
          />
        </div>
      ))}
    </div>
    <div className={styles.productFeatureRow} data-testid={'cons-row'}>
      <div className={styles.rowLabel}>
        <TypographyV2 textStyle="sb-text-interface-body-small-regular" tag="span">
          Cons
        </TypographyV2>
      </div>
      {data.map((product) => (
        <div className={styles.tableDataRowValue} key={`cons-${product.businessKey}`}>
          <ProsCons
            className={styles.prosConsList}
            type="cons"
            list={isPaidMember ? product.cons : []}
            displayHeading={false}
          />
        </div>
      ))}
    </div>
  </div>
)

type RenderTableDataArgs = {
  tableData: ComparisonTableState['tableData']
  useStarRatingBadge: boolean
  useTickCrossIcons: boolean
  showRefine: boolean
  onTableRowCheckbox: ({ value, sectionLabel, rowLabel }: OnTableRowCheckboxArgs) => void
}

export type ComparisonTablePageProps = {
  taxonomySlug: string
  renderWithAccordion: boolean
}
