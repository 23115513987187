import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Button,
  Checkbox,
  Grid,
  GridItem,
  PageTitle,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import classnames from 'classnames'

import type { Meta } from '../../../generated/frontend'
import { useLoginMutation } from '../../../generated/frontend'
import { ErrorComponent } from '../../../shared/components/Error'
import type { Props } from '../../../shared/components/InfoBanner'
import { InfoBanner } from '../../../shared/components/InfoBanner'
import { Link } from '../../../shared/components/Link'
import { useBaseMetaTags } from '../../../shared/hooks/useBaseMetaTags'
import { FormFields } from '../components/FormFields'
import { PasswordFieldView } from '../components/PasswordField'
import { Links, LoginPageBannerText, UsernameField } from '../data'
import { accountPagesDataLayer, setNewRelicTransaction } from '../utils'
import styles from './LoginPage.module.scss'
import { processLoginMutationResponse } from './loginUtils'

const LoginPage: FunctionComponent = () => {
  const [content, setContent] = useState('')
  const [context, setContext] = useState<Props['context']>('error')
  const [stayLoggedin, setStayLoggedIn] = useState(true)
  const [showInfoBanner, setShowInfoBanner] = useState(false)
  const [title, setTitle] = useState('')

  const { getBaseMetaTags } = useBaseMetaTags()
  const methods = useForm({ mode: 'onBlur' })
  const { handleSubmit } = methods

  const kinde = useKindeAuth()

  const kindeLoginFeature = useFeatureIsOn('paywall-kinde-login')
  const [loginMutation, { data, error, loading }] = useLoginMutation()

  setNewRelicTransaction('account/login')

  const metaTagObj = {
    canonical: 'https://www.which.co.uk/login',
    title: "Login to which.co.uk | Discover Best Buys & Don't Buys",
    fbAppId: '320949621749168',
    desc: 'From this page members can log directly into which.co.uk. Access thousands of expertly tested independent reviews to help you make confident choices. Please have your email address or username and password ready.',
  }

  const getMetaTags = ({ meta, imageUrl, twitterCard }: LoginMetaArgs) => {
    return [...getBaseMetaTags({ meta, imageUrl, twitterCard })]
  }

  useEffect(() => {
    if (data && data.login) {
      const { login } = data

      if (login.__typename === 'AuthenticationError') {
        setShowInfoBanner(true)
        setContent(LoginPageBannerText.authErrorContentText)
        setContext('error')
        setTitle(LoginPageBannerText.authErrorText)
        dynamicGa4DataLayerPush({
          event: 'notification_error',
          action_group: 'login',
          item_text: LoginPageBannerText.authErrorText,
          utagid: 'AUP4858DP01',
        })
      }
    }
  }, [data])

  useEffect(() => {
    if (document.cookie.includes('ref=reset-password')) {
      document.cookie = 'ref= ; '
      setShowInfoBanner(true)
      setContent(LoginPageBannerText.Success)
      setContext('correct')
      setTitle('')
    }
  }, [])

  const handleOnSubmit = (formObj) => {
    dynamicGa4DataLayerPush({
      event: 'click_button',
      item_text: 'Log in',
    })
    if (kindeLoginFeature && kinde && kinde.login) {
      kinde.login({
        authUrlParams: {
          connection_id: process.env.KINDE_CONNECTION_ID,
          login_hint: formObj.username,
        },
      })
    } else {
      setShowInfoBanner(false)
      loginMutation({
        variables: {
          username: formObj.username,
          password: formObj.password,
        },
      })
    }
  }

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (data && data.login) {
    const { login } = data

    processLoginMutationResponse({
      login,
      stayLoggedIn: stayLoggedin,
    })
  }
  return (
    <>
      <Helmet>
        {getMetaTags({
          meta: metaTagObj,
          imageUrl: 'https://www.which.co.uk/login',
          twitterCard: 'summary_large_image',
        })}
        <script>
          {accountPagesDataLayer({
            content_type: 'login',
          })}
        </script>
      </Helmet>
      <Grid className={classnames(styles.loginPage, showInfoBanner && styles[context])}>
        <GridItem span={{ medium: 6, large: 4 }} columnStart={{ medium: 4, large: 5 }}>
          {showInfoBanner && <InfoBanner content={content} context={context} title={title} />}
          <PageTitle pageTitle="Log into Which?" pageTitleTag="h1" />

          <FormProvider {...methods}>
            <form method="post">
              <FormFields fields={UsernameField} />
              {!kindeLoginFeature && (
                <PasswordFieldView
                  displayPasswordCheckList={false}
                  name="password"
                  label="Password"
                  rulesRequired={false}
                  calledFrom="login"
                  errorMessageText="Please enter your password"
                  maxLength="50"
                  showRequiredText={false}
                  autoComplete="password"
                  validation={{
                    required: true,
                  }}
                />
              )}
              <div className={styles.loginPageFormFooter}>
                <Checkbox
                  type="checkbox"
                  className={styles.loginPageStayLoggedInDiv}
                  name="stay-logged-in"
                  id="stay-logged-in"
                  value=""
                  label="Stay logged in"
                  checked={stayLoggedin}
                  onChangeCallback={() => {
                    setStayLoggedIn(!stayLoggedin)
                  }}
                />
                <div className={styles.loginPageForgotPassword}>
                  <Link
                    appearance="primary"
                    href={Links.forgotPassword}
                    textStyle="sb-text-interface-body-small-regular"
                    data-which-id="link"
                    className={styles.loginPageForgotPasswordLink}
                  >
                    Forgotten password?
                  </Link>
                </div>
              </div>
              <Typography
                textStyle="sb-text-body-x-small-regular"
                className={styles.loginPageUntickText}
              >
                Untick if you're using a public device
              </Typography>
              <Button
                data-testid="login-button"
                enableSpinner={loading}
                onClick={handleSubmit((formObj) => handleOnSubmit(formObj))}
                className={styles.loginPageSubmit}
              >
                Log in
              </Button>
            </form>
          </FormProvider>
          <Typography
            textStyle="sb-text-interface-body-small-regular"
            className={styles.loginPageSignupLink}
          >
            Not a Which? member?{' '}
            <Link
              data-which-id="link"
              href={Links.join}
              textStyle="sb-text-interface-body-small-regular"
            >
              Sign up now
            </Link>
          </Typography>
        </GridItem>
      </Grid>
    </>
  )
}

export default LoginPage

/////// IMPLEMENTATION /////////

type LoginMetaArgs = {
  meta: Partial<Meta>
  imageUrl: string
  twitterCard: string
}
