import type { FunctionComponent } from 'react'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, FormField, Heading, InputV2, TypographyV2 as Typography } from '@which/seatbelt'
import type { InputTypes } from '@which/seatbelt/build/src/components/Seatbelt2/BuildingBlocks/Forms/Input/Input'

import { Link } from '../../Link'
import styles from './NewsletterSignUpForm.module.scss'

export const NewsletterSignUpForm: FunctionComponent<NewsletterFormProps> = ({
  onFocus,
  onSignUp,
  buttonText,
  withAllFields,
  description,
  additionalText,
  disclaimerText,
  unsubscribeText,
  header,
  errorMsg,
}) => {
  const { control, handleSubmit } = useForm({ mode: 'onBlur' })

  const renderField = ({
    field,
    label,
    inputType = 'text' as InputTypes,
    required = true,
    tooltip = '',
    validation,
  }) => {
    const optionalEventHandlers =
      typeof onFocus === 'function' && field === 'email' ? { onFocus } : ({} as const)
    return (
      <Controller
        control={control}
        name={field}
        defaultValue=""
        rules={validation}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
          const message = error ? `Please enter a valid ${label}` : undefined

          return (
            <FormField
              label={label}
              labelFor={field}
              className={styles[field]}
              tooltip={tooltip}
              required={required}
              {...{ errorMsg: message }}
            >
              <InputV2
                inputRef={ref as unknown as React.RefObject<HTMLInputElement>}
                aria-invalid={!!error}
                name={field}
                type={inputType}
                value={value}
                {...optionalEventHandlers}
                onChange={onChange}
                onBlur={onBlur}
                className={styles[`${field}-input`]}
              />
            </FormField>
          )
        }}
      />
    )
  }

  const SubmitButton = (
    <Button
      className={styles.signUpButton}
      type="submit"
      data-testid="sign-up-button"
      onClick={handleSubmit((data) => onSignUp(data))}
    >
      {buttonText}
    </Button>
  )

  return (
    <div
      className={`${styles.newsletterForm} ${withAllFields ? styles.allFields : styles.emailOnly}`}
      data-testid="newsletter-signup-form"
    >
      <div className={styles.newsletterFormHeaderContainer}>
        <Heading heading={header} headingTag="h2" headingType="large" />
        <Typography className={styles.newsletterFormOverline} textStyle="sb-text-heading-overline">
          free newsletter
        </Typography>
        <div className={styles.newsletterFormDescription}>
          {typeof description === 'string' ? <p>{description}</p> : description}
        </div>
        <div className={styles.newsletterFormDescription}>
          {typeof additionalText === 'string' ? <p>{additionalText}</p> : additionalText}
        </div>
      </div>
      <form method="post">
        {withAllFields ? (
          <>
            <div className={styles.newsletterFormName}>
              {renderField({
                field: 'firstName',
                label: 'First name',
                validation: { required: true },
              })}
              {renderField({
                field: 'lastName',
                label: 'Last name',
                validation: { required: true },
              })}
            </div>
            {renderField({
              field: 'email',
              label: 'Email address',
              inputType: 'email',
              validation: {
                required: true,
                type: 'email',
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              },
            })}
            {renderField({
              field: 'postcode',
              label: 'Postcode',
              required: false,
              tooltip:
                "We use postcode data to help us understand who uses our services. You do not have to provide this to us, but we're including it because we'd like to reach as wide a range of people as possible and by obtaining this information we can ensure that we're doing this.",
              validation: {
                pattern: /(gir\s*0aa)|^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i,
              },
            })}
            {disclaimerText && (
              <div className={styles.newsletterFormDisclaimerText}> {disclaimerText} </div>
            )}
            {SubmitButton}
          </>
        ) : (
          <div className={styles.newsletterFormEmailForm}>
            {renderField({
              field: 'email',
              label: 'Email address',
              inputType: 'email',
              validation: {
                required: true,
                type: 'email',
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              },
            })}
            {SubmitButton}
          </div>
        )}
      </form>
      {errorMsg && (
        <span className={styles.submitError} data-testid="submit-error">
          {errorMsg}
        </span>
      )}
      {unsubscribeText ? (
        <div className={styles.newsletterFormUnsubscribe}>{unsubscribeText}</div>
      ) : (
        <Typography
          textStyle="sb-text-body-default-regular"
          className={styles.newsletterFormUnsubscribe}
        >
          Unsubscribe whenever you want. Your data will be processed in accordance with our{' '}
          <Link
            href="https://www.which.co.uk/help/which-privacy-notice-aGL107i27ybI"
            target="_blank"
            textStyle="sb-text-interface-body-x-small-regular"
          >
            Privacy policy
          </Link>
        </Typography>
      )}
    </div>
  )
}

export type NewsletterFormProps = {
  onFocus?: () => void
  onSignUp: (NewsletterFormData) => void
  buttonText?: string
  withAllFields?: boolean
  description: string | JSX.Element
  additionalText?: JSX.Element
  disclaimerText?: JSX.Element
  unsubscribeText?: JSX.Element
  header: string
  errorMsg?: string
}
