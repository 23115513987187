import { type FunctionComponent } from 'react'
import { Grid, GridItem, Heading, Link, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import type {
  Recommendations,
  RecommendedContent as RecommendedContentType,
} from '../../../../generated/frontend'
import { AdviceCard } from '../AviceCard'
import { ProductReviewCard } from '../ProductReviewCard'
import styles from './RecommendedContent.module.scss'

const ContentCard: FunctionComponent<ContentCardProps> = ({ content, whichId, modelId, index }) => {
  if (content.__typename === 'RecommendedArticle') {
    return (
      <AdviceCard
        title={content.title}
        subheading={content.subheading}
        primaryLink={content.href}
        imgObj={{
          imageAlt: content.title,
          str: content.image,
        }}
        ariaLabel={`${content.title} advice card`}
        trackingData={{
          'data-which-id': whichId,
          'data-section': 'Recommended for you',
          'data-card-name': content.title,
          'data-index': (index + 1).toString(),
          'data-content-id': content.id,
          'data-model-id': modelId,
        }}
      />
    )
  } else if (content.__typename === 'RecommendedProduct') {
    return (
      <ProductReviewCard
        title={content.manufacturer}
        model={content.model}
        price={content.price}
        testScore={content.testScore}
        primaryLink={`/${content.href}`}
        imgObj={{
          imageAlt: content.model,
          str: content.image,
        }}
        ariaLabel={`${content.manufacturer} ${content.model} review`}
        trackingData={{
          'data-which-id': whichId,
          'data-section': 'Recommended for you',
          'data-card-name': content.model,
          'data-index': (index + 1).toString(),
          'data-content-id': content.id,
          'data-model-id': modelId,
        }}
      />
    )
  }

  return null
}

export const RecommendedContent: FunctionComponent<RecommendedContentProps> = ({
  content,
  whichId,
  modelId,
}) => {
  return (
    <Grid data-testid={'r4u-2x2'} className={classNames('w-page-wrapper', styles.sectionSpacing)}>
      <GridItem
        columnStart={{ large: 2 }}
        span={{ large: 10 }}
        className={styles.mobileMaxWidthWrapper}
      >
        <Heading headingType="large" heading="Based on your interests" headingTag="h2" />

        <div className={styles.recommendedArticlesContainer}>
          {content?.map((content, index) => (
            <ContentCard
              content={content}
              whichId={whichId}
              modelId={modelId}
              index={index}
              key={content.id}
            />
          ))}
        </div>
        <Typography textStyle="sb-text-body-default-regular" className={styles.feedbackSurvey}>
          This is a new feature. Help us improve these recommendations by{' '}
          <Link href="https://which.qualtrics.com/jfe/form/SV_dh9TZ6UW2uJ0EKi">
            giving your feedback
          </Link>
        </Typography>
      </GridItem>
    </Grid>
  )
}

type RecommendedContentProps = {
  content?: Recommendations['models'][0]['content']
  whichId: string
  modelId: string
}

type ContentCardProps = {
  content: RecommendedContentType
  whichId: string
  modelId: string
  index: number
}
