import { useFeatureValue } from '@growthbook/growthbook-react'

import type { Recommendations } from '../../../../generated/frontend'
import { RecommendedArticles } from '../RecommendedArticles'
import { RecommendedContent } from '../RecommendedContent'

const RecommendedForYouComponent = ({
  recommendations,
  whichId,
  modelId,
}: {
  recommendations: Recommendations
  whichId: string
  modelId: string
}) => {
  const use2x2Layout = useFeatureValue('recommended-for-you-2x2-layout', false)

  const modelToUse = 'mixed/adviceOnly'
  const matchingModel = recommendations.models.find((model) => model.model === modelToUse)

  if (!matchingModel || !matchingModel.content) {
    return null
  }

  return use2x2Layout ? (
    <RecommendedContent content={matchingModel.content} whichId={whichId} modelId={modelId} />
  ) : (
    <RecommendedArticles content={matchingModel.content} whichId={whichId} modelId={modelId} />
  )
}

export const RecommendedForYouContainer = ({
  recommendations,
  whichId,
  modelId,
}: {
  recommendations: Recommendations
  whichId: string
  modelId: string
}) => {
  const allModelsHaveContent = recommendations.models
    .filter((model) => model.model.includes('mixed'))
    .every((model) => model.content !== null)

  if (!allModelsHaveContent) {
    return null
  }

  return (
    <RecommendedForYouComponent
      recommendations={recommendations}
      whichId={whichId}
      modelId={modelId}
    />
  )
}
