import { type FunctionComponent } from 'react'
import { ContentCardV2, Grid, GridItem, Heading, Link, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import type { Recommendations } from '../../../../generated/frontend'
import styles from './RecommendedArticles.module.scss'

export const RecommendedArticles: FunctionComponent<RecommendedProps> = ({
  content,
  whichId,
  modelId,
}) => {
  return (
    <Grid
      className={classNames('w-page-wrapper', styles.sectionSpacing)}
      data-testid={'r4u-single-row'}
    >
      <GridItem
        columnStart={{ large: 2 }}
        span={{ large: 10 }}
        className={styles.mobileMaxWidthWrapper}
      >
        <Heading headingType="large" heading="Based on your interests" headingTag="h2" />

        <div className={styles.recommendedArticlesContainer}>
          {content?.map(
            (content, index) =>
              content.__typename === 'RecommendedArticle' && (
                <ContentCardV2
                  key={content.id}
                  title={content.title}
                  primaryLink={content.href}
                  description={content.subheading}
                  imgObj={{
                    imageAlt: content.title,
                    str: content.image,
                  }}
                  trackingData={{
                    'data-which-id': whichId,
                    'data-section': 'Recommended for you',
                    'data-card-name': content.title,
                    'data-index': (index + 1).toString(),
                    'data-content-id': content.id,
                    'data-model-id': modelId,
                  }}
                />
              )
          )}
        </div>
        <Typography textStyle="sb-text-body-default-regular" className={styles.feedbackSurvey}>
          This is a new feature. Help us improve these recommendations by{' '}
          <Link href="https://which.qualtrics.com/jfe/form/SV_dh9TZ6UW2uJ0EKi">
            giving your feedback
          </Link>
        </Typography>
      </GridItem>
    </Grid>
  )
}

type RecommendedProps = {
  content: Recommendations['models'][0]['content']
  whichId: string
  modelId: string
}
